import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/featured.css";
import { getFeaturesProjects } from "../Connection";

function Featured() {

  const [projects, setProjects] = useState([]);
  // const [arrayList, setArrayList] = useState(['List Item 1', 'List Item 2', 'List Item 3']);

  useEffect(() => {
    getFeaturesProjects(result => {
      // console.log("features ", result);
      Object.keys(result).forEach((item) => {
        // console.log(item,  '  -> ', result[item]);
        Object.entries(result[item]).forEach(project => {
          
          if (project[1]['ISHIDE'] !== true) {
            setProjects(projects => [...projects,
            {
              'name': project[0],
              'logo': project[1]['LOGO'],
              'link': project[1]['LINK']
            }])
          }

        })
      })
    })
  }, [])

  function generateTimestamp() {
    return Date.now();
  }

  // console.log("Featured :", projects);

  return (
    <>
      <div className="f-wrapper">
        <div className="f-header">
          <div className="f-title">
            <h4>Play Games</h4>
          </div>
        </div>
        <div className="f-container">
          <div className="f-box">

            
              {
                projects.map((item, index) => (
                  <div key={index}>
                    <div className="f-box-inner">
                      <Link
                        className="f-item"
                        to={item.link.replaceAll('{aryoId}', generateTimestamp())}
                      >
                        <img
                          src={item.logo}
                          alt=""
                          className="f-logo"
                          id="digial_tv"
                        />

                        <p id="f-name">{item.name}</p>
                      </Link>
                    </div>
                  </div>
                  
                ))
              }
            

          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Featured;
