import React from 'react';
import '../css/PincodeLoader.css';

const PincodeLoader = () => {
  return (
<div className="loader-container">
  <div className="loader"></div>
</div>
  );
};

export default PincodeLoader;